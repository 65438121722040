export const GET_REQUEST_LIST = "GET_REQUEST_LIST";
export const GET_ALL_ACTIVE_REQUEST_LIST = "GET_ALL_ACTIVE_REQUEST_LIST";
export const GET_ADMIN_PENDING_REQUEST_LIST = "GET_ADMIN_PENDING_REQUEST_LIST";
export const GET_ADMIN_ACCEPTED_LIST = "GET_ADMIN_ACCEPTED_LIST";
export const GET_DELIVERED_NOT_ACCEPTED = "GET_DELIVERED_NOT_ACCEPTED";
export const GET_EDIT_REQUEST_DATA = "GET_EDIT_REQUEST_DATA";
export const GET_POLL_REQUEST_LIST = "GET_POLL_REQUEST_LIST";
export const GET_ADMIN_ASSIGN_REQUEST_LIST = "GET_ADMIN_ASSIGN_REQUEST_LIST";
export const GET_DESIGNER_ASSIGNED_REQUEST_LIST = "GET_DESIGNER_ASSIGNED_REQUEST_LIST";
export const GET_DESIGNER_ACTIVE_REQUEST_LIST = "GET_DESIGNER_ACTIVE_REQUEST_LIST";
export const DELIEVER_REQUEST_DATA = "DELIEVER_REQUEST_DATA";
export const GET_CUSTOMER_ACTIVE_REQUEST_LIST = "GET_CUSTOMER_ACTIVE_REQUEST_LIST";
export const GET_SUPER_ADMIN_APPROVE_REQUEST_LIST = "GET_SUPER_ADMIN_APPROVE_REQUEST_LIST";
export const SUBMIT_NOW = "SUBMIT_NOW";
export const GET_FEEDBACK_QUE = "GET_FEEDBACK_QUE";
export const GET_ALL_PAST_REQUEST_LIST = "GET_ALL_PAST_REQUEST_LIST";
export const GET_DELIVER_REQUEST = "GET_DELIVER_REQUEST";
export const GET_DESIGNER_PAST_REQUEST_LIST = "GET_DESIGNER_PAST_REQUEST_LIST";
export const GET_NEW_REQUEST = "GET_NEW_REQUEST";
export const GET_EXPAND_REQUEST_DETAILS = "GET_EXPAND_REQUEST_DETAILS";
export const GET_CUSTOMERS_PAYMENT_HISTORY = "GET_CUSTOMERS_PAYMENT_HISTORY";
export const GET_DESIGNERS_PAYMENT_HISTORY = "GET_DESIGNERS_PAYMENT_HISTORY";
export const GET_DOWNLOAD_PATH = "GET_DOWNLOAD_PATH";
export const GET_ALL_DRAFTS = "GET_ALL_DRAFTS";
export const GET_ALL_REVIEW_REQUESTS = "GET_ALL_REVIEW_REQUESTS";
export const UPLOAD_NEW_IMAGE = "UPLOAD_NEW_IMAGE";
export const GET_LATE_REQUESTS = "GET_LATE_REQUESTS";
export const GET_COMPLETED_REQUEST = "GET_COMPLETED_REQUEST";
export const GET_REVIEW_REQUEST_DATA = "GET_REVIEW_REQUEST_DATA";
export const GET_COMPLETED_REQUEST_DATA = "GET_COMPLETED_REQUEST_DATA";
export const GET_ALL_FEEDBACK_REQUESTS = "GET_ALL_FEEDBACK_REQUESTS";